<template>
  <div class="container wrapper wrapper_space-top">
    <h1 class="msg" v-if="!$route.query.edit">
      Your post is completed.
      <br />
      It is now live!
    </h1>
    <h1 class="msg" v-else>
      Changes has been
      <br />
      applied.
    </h1>
    <div class="d-flex justify-center btn-holder2">
      <div class="text-center">
        <v-btn class="btn1" @click="$router.push('/job_organizer')">
          See Post
        </v-btn>

        <div
          v-if="!$route.query.edit"
          class="underlineBtn mt-5"
          @click="
            $router.push({
              path: '/create_job/invitation?',
              query: { job_id: $route.query.job_id }
            })
          "
        >
          Direct invitation
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'JobFormDone'
  };
</script>
<style lang="scss">
  .msg {
    font-size: 3rem;
    text-transform: uppercase;
  }
  .btn-holder2 {
    margin-top: 200px;
    margin-bottom: 70px;
  }
  @media (max-width: 599px) {
    .msg {
      font-size: 2rem;
    }
    .btn-holder2 {
      margin-top: 100px;
    }
  }
</style>
